const QUERIES = {
  USERS_LIST: 'users-list',
  CUSTOMERS_LIST: 'customers-list',
  PROJECTS_LIST: 'projects-list',
  ISSUES_LIST: 'issues-list',
  FOLLOWUPS_LIST: 'followups-list',
}

const STATUS = [
  {value: 'ouvert', label: 'Ouvert', color: 'success'},
  {value: 'en cours', label: 'En cours', color: 'warning'},
  {value: 'en ligne', label: 'En ligne', color: 'info'},
  {value: 'en suspend', label: 'En suspend', color: 'warning'},
  {value: 'annulé', label: 'Annulé', color: 'danger'},
  {value: 'non valide', label: 'Non valide', color: 'warning'},
  {value: 'correction invalide', label: 'Correction invalide', color: 'warning'},
  {value: 'a dicuter', label: 'A discuter', color: 'warning'},
  {value: 'ferme', label: 'Fermé', color: 'danger'},
]

export {QUERIES, STATUS}
